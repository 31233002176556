import Button from '@/components/atoms/Button';
import Slider from '@/components/molecules/Slider';
import { ActionBlockData } from '@/services/types/blocksTypes';
import { checkIsValidColor, getImage } from '@/services/utils/helper';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './styles.module.scss';
import BlockSeeAll from '@/components/dynamics/SeeAll';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
	block_color_code?: string;
}

const ActionVerticalSideScroll: React.FC<ActionsProps> = ({ data, label, blockLinks, block_color_code }) => {
	const { link, children } = blockLinks;
	return (
		<div className={cls('py-30 ', styles.category_card_wrapper)}>
			<div className='container product-slider'>
				<div className='d-flex justify-space-between items-center mb-30'>
					<div className='text-30 text-grey900 fw-600'>{label}</div>
					<BlockSeeAll title='see all' link={link} color={block_color_code} data={data} />
				</div>
				<Slider
					slidesPerView={6}
					spaceBetween={20}
					shouldSlidesPerGroup={true}
					responsive={{
						0: {
							slidesPerView: 2,
							spaceBetween: 10
						},

						768: {
							slidesPerView: 4,
							spaceBetween: 10
						},
						1024: {
							slidesPerView: 4,
							spaceBetween: 10
						},
						1280: {
							slidesPerView: 4,
							spaceBetween: 10
						},
						1400: {
							slidesPerView: 6,
							spaceBetween: 20
						},
						1600: {
							slidesPerView: 6,
							spaceBetween: 20
						}
					}}
					itemClassName={styles.category_card}
					className='product-slider-category'>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<Link
								href={children[i]?.link}
								key={i}
								style={{
									borderRadius: 12
								}}>
								<div
									style={{
										backgroundColor: `${d?.color_code}`,
										borderRadius: 12
									}}>
									<div className={styles.category_card__image}>
										<Image
											alt={d?.alt}
											src={getImage(d.file)}
											width={110}
											height={110}
											priority
											unoptimized
										/>
									</div>
									<div className={styles.category_card__content}>
										<div className='text-black text-grey700 fw-600 text-center'>{d?.title}</div>
										<div className='text-error mb-5'>{d.subtitle}</div>
									</div>
								</div>
							</Link>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default ActionVerticalSideScroll;
