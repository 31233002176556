import BlockSeeAll from '@/components/dynamics/SeeAll';
import { ActionBlockData } from '@/services/types/blocksTypes';
import { getImage } from '@/services/utils/helper';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './styles.module.scss';
import LinkWrapper from '@/components/atoms/LinkWrapper';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
	block_color_code?: string;
}

const Action6ColumnGrid: React.FC<ActionsProps> = ({ data, label, blockLinks, block_color_code }) => {
	const { link, children } = blockLinks;
	return (
		<div className={cls('py-30 ', styles.category_card_wrapper)}>
			<div className='container product-slider'>
				{/* <div className="d-flex items-center mb-30 justify-center">
					<div className="text-30 text-grey900 fw-600 ">{label}</div>
				</div> */}
				<div className='d-flex justify-space-between items-center mb-30'>
					<div className='text-30 text-grey900 fw-600'>{label}</div>
					<BlockSeeAll title='see all' link={link} color={block_color_code} data={data} />
				</div>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: 'repeat(6, 1fr)',
						gridGap: 20
					}}>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<div className='d-flex flex-column' key={i}>
								<div className={styles.category_card}>
									<LinkWrapper
										data={{
											type: d?.link,
											id: d?.uuid,
											name: d?.title
										}}
										href={children[i]?.link}
										key={i}
										style={{
											borderRadius: 12
										}}>
										<div
											style={{
												backgroundColor: `${d?.color_code}`,
												borderRadius: 12
											}}>
											<div className={styles.category_card__image}>
												<Image alt={d?.alt} src={getImage(d.file)} width={200} height={200} priority />
											</div>
										</div>
									</LinkWrapper>
								</div>
								<div className={styles.category_card__content}>
									<LinkWrapper
										data={{
											type: d?.link,
											id: d?.uuid,
											name: d?.title
										}}
										href={children[i]?.link}
										key={i}
										style={{
											borderRadius: 12
										}}>
										<div className='text-black text-grey700 fw-600 text-center'>{d?.title}</div>
									</LinkWrapper>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Action6ColumnGrid;
