import Slider from '@/components/molecules/Slider';
import generateBlockLink from '@/services/utils/LinkGenerator';
import { checkIsValidColor } from '@/services/utils/helper';
import cls from 'classnames';
import { map } from 'lodash';
import Link from 'next/link';
import FeaturedBrandSingle from './FeaturedBrandSingle';
import styles from './featured-brands.module.scss';
import BlockSeeAll from '@/components/dynamics/SeeAll';

const FeaturedBrands = ({ data }) => {
	const { block, block_data, block_color_code, block_machine_name } = data;
	const { children, link } = generateBlockLink(data);
	return (
		<div className={cls('py-30 ', styles.featured_brands)}>
			<div className='container product-slider'>
				<div className='d-flex justify-space-between items-center mb-60'>
					<h2 className='text-30 text-grey900 fw-600 mb-0'>{block}</h2>

					{block_data?.length > 5.6 && (
						<BlockSeeAll title='see all' link={link} color={block_color_code} data={data} />
					)}
				</div>
				<Slider
					style={{ paddingInlineStart: '10px' }}
					slidesPerView={5.5}
					spaceBetween={20}
					shouldFetchData={block_machine_name}
					SlideComponent={FeaturedBrandSingle}
					responsive={{
						0: {
							slidesPerView: 2,
							spaceBetween: 8
						},
						320: {
							slidesPerView: 2,
							spaceBetween: 8
						},
						480: {
							slidesPerView: 2,
							spaceBetween: 8
						},
						768: {
							slidesPerView: 2.5,
							spaceBetween: 8
						},
						1024: {
							slidesPerView: 2.8,
							spaceBetween: 8
						},
						1200: {
							slidesPerView: 3.4,
							spaceBetween: 8
						},
						1280: {
							slidesPerView: 3.5,
							spaceBetween: 8
						},

						1300: {
							slidesPerView: 3.7,
							spaceBetween: 8
						},
						1350: {
							slidesPerView: 4.1,
							spaceBetween: 8
						},
						1440: {
							slidesPerView: 4.5,
							spaceBetween: 8
						},
						1400: {
							slidesPerView: 4.5,
							spaceBetween: 8
						},

						1600: {
							slidesPerView: 5.5,
							spaceBetween: 8
						}
					}}>
					{map(block_data, (d, i) => (
						<FeaturedBrandSingle
							data={{
								...d,
								link: children[i]?.link
							}}
							key={i}
						/>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default FeaturedBrands;
